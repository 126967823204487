body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  margin: 0; }

.app {
  position: relative;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center; }
  .app .logo-container {
    position: relative;
    z-index: 2;
    width: 270px;
    height: 29px;
    padding: 25px;
    max-width: 320px;
    background: #ffffff;
    border: #e6e6e6 1px solid; }
    .app .logo-container .logo {
      width: 270px;
      height: 29px; }
  .app .background {
    position: absolute;
    z-index: 1;
    margin-top: 60px;
    width: 100vw;
    min-height: 120px;
    background: url(data:image/gif;base64,R0lGODlhYAAdAIAAAMzMzP///yH5BAAAAAAALAAAAABgAB0AAAKIjGGBl6sNmXzT2UjzrXhv3YXcCJLfKVKoma7l68aizNZwi9tffus83QsugMSf0VcrIoVHZrKpdEqjDqh1ST1msddpbuvlinvgchd8To/N4TbbHXnL1WT6fM2z6/HIO5zv5Ce4NwhYmPCXeLiyuKdo6Nj4CEkZFHlZOYQ5yamZuckm2UkHWrpWAAA7) repeat; }

