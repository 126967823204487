body {
  margin: 0;
}

.app {
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .logo-container {
    position: relative;
    z-index: 2;
    width: 270px;
    height: 29px;
    padding: 25px;
    max-width: 320px;
    background: #ffffff;
    border: #e6e6e6 1px solid;

    .logo {
      width: 270px;
      height: 29px;
    }
  }

  .background {
    position: absolute;
    z-index: 1;
    margin-top: 60px;
    width: 100vw;
    min-height: 120px;
    background: url("bg.gif") repeat;
  }
}


